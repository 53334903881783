<template>
  <Layout class="bg-gray-50 ">
    <PageHeader title="Reset Password" />
    <div v-if="loading" class="d-flex justify-content-center mb-3">
      <b-spinner
        style="width: 3rem; height: 3rem;"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <div class="row bg-gray-50 rounded-2xl py-3">
      <div class="col-md-8  mobile">
        <form class="rounded-3xl border-2 p-4">
          <div class="form-group  mb-5">
            <label for="Email Address">Email Address</label>
            <input
              type="text"
              class="form-control"
              v-model="userInput"
              placeholder="enter email address"
            />

            <a
              class="float-right font-bold text-lg pt-2"
              @click.prevent="sendOTP"
              href="#"
              ><u>Send OTP</u></a
            >
          </div>

          <div class="form-group">
            <label for="OfficePhone"> Verify Code (OTP)</label>
            <input
              type="text"
              v-model="passcode"
              placeholder="enter verification code sent"
              class="form-control"
            />
          </div>

          <div class="form-group formControl ">
            <label for="New Password">New Password</label>
            <input
              type="password"
              class="form-control"
              v-model="password"
              name="password"
              placeholder="**************"
            />
          </div>
          <div class="form-group formControl">
            <label for="Re-Enter Password">Confirm Password</label>
            <input
              type="password"
              class="form-control"
              name="password"
              placeholder="*************"
              v-model="confirm_password"
            />
          </div>
        </form>

        <button
          @click.prevent="ResetPassword"
          class="btn my-4 btn-warning btn-block"
        >
          <i class="fa fa-unlock" aria-hidden="true"></i> Reset Password
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Reset Password",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      loading: false,
      userInput: "",
      passcode: "",
      password: "",
      confirm_password: ""
    };
  },

  methods: {
    sendOTP() {
      this.loading = true
      // eslint-disable-next-line
      const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (emailRegx.test(this.userInput)) {
        return this.$store
          .dispatch("dashboard/EmailOTP", this.userInput)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false
              this.userInput = "";
              this.$swal({
                icon: "info",
                title: "Success",
                text: "OTP Sent check your mail"
              });
            }
          })
          .catch(err=>{
            this.loading = false
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: err.response.data
            });
          })
          
      } else if (this.userInput.match(/^\d{10}$/)) {
        this.loading = true
        return this.$store
          .dispatch("dashboard/PhoneOTP", this.userInput)
          .then((response) => {
            if (response.status == 200) {
              this.userInput = "";
              this.loading = false
              this.$swal({
                icon: "info",
                title: "Success",
                text: "OTP Sent successfully"
              });
            } 
          })
          .catch((err) => {
            this.loading = false
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: err.response.data
            });
          });
      } else
      this.loading = false
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "nvalid input"
        });
    },

    ResetPassword() {
      this.loading = true
      const data = {
        pass_passcode: this.passcode,
        pass_password: this.password
      };

      if (this.password != this.confirm_password) {
        return this.$toasted.error("Password do not match", { duration: 5000 });
      }
      return this.$store
        .dispatch("dashboard/ResetPassword", data)
        .then((response) => {
          if (response.status == 200) {
            this.loading = false
            this.$swal({
              icon: "info",
              title: "Success",
              text: "password reset successfully"
            });

            localStorage.clear();
            window.location.reload();
          } else
            return this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Oooop! an error has occured"
            });
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    }
  }
};
</script>

<style scoped>
.btn-warning {
  background: #0c4369 !important;
  border: none;
}

.btn-primary {
  background: #f15825;
  border: none;
}

/* #imageUpload
{
    display: none;
} */
input[type="file"] {
  color: transparent;
}
#profileImage {
  cursor: pointer;
}

#profile-container {
  width: 150px;
  height: 150px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

#profile-container img {
  width: 150px;
  height: 150px;
}
</style>
